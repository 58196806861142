@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'zen-maru-gothic', sans-serif;
  font-weight: 400;
}

body {
  width: 100%;
}

.calc-height {
  min-height: calc(100vh - 135px);
}

.menu-list a {
  position: relative;
  display: inline-block;
  text-decoration: none;
}

.menu-list a::after {
  position: absolute;
  bottom: -3px;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #000000;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform 0.3s;
}

.menu-list a:hover::after {
  transform: scale(1, 1);
}

.menu-list a.active::after {
  position: absolute;
  bottom: -3px;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #000000;
  transform: scale(1, 1);
  transform-origin: center top;
  transition: transform 0.3s;
}

.category-1 {
  @apply text-center py-2 border border-yellow-300 rounded-full cursor-pointer bg-yellow-100 hover:bg-yellow-200;
}

.category-2 {
  @apply text-center py-2 border border-sky-300 rounded-full cursor-pointer bg-sky-100 hover:bg-sky-200;
}

.category-3 {
  @apply text-center py-2 border border-pink-300 rounded-full cursor-pointer bg-pink-100 hover:bg-pink-200;
}

.course {
  @apply flex items-center justify-between p-3 mb-2 cursor-pointer text-lg rounded;
}

.course span {
  @apply text-sm text-center bg-white rounded-full py-1 px-3;
}

.description p {
  @apply mb-2;
}

.description h5 {
  @apply border-b border-black pb-1 mb-3;
}

.company th {
  @apply text-right py-5 w-1/4 font-medium;
}

.company td {
  @apply text-base;
}

.academy-strategy li {
  @apply bg-white p-3 lg:p-5 rounded-lg shadow flex flex-col items-center;
}

.academy-strategy li h6 {
  @apply text-xs border border-neutral-500 px-2 rounded-full mb-2;
}

.academy-strategy li h5 {
  @apply text-center text-lg mb-3 lg:mb-5;
}

.academy-strategy li span {
  @apply bg-secondary w-16 h-1 mb-5 rounded-full;
}

.academy-strategy li p {
  @apply text-sm;
}

.youtube iframe.youtube-16-9 {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}

.course-description p {
  @apply mb-2;
}

.history {
  @apply mb-2;
}

.policy h5 {
  @apply font-bold border-b border-neutral-300 pb-2 mb-5;
}

.policy-content {
  @apply mb-5;
}
